







































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_USER_DETAILS,
  USER_DETAILS_BY_ID,
  USER_PROFILE,
  USER_PROFILE_BY_ID
} from "@/store/modules/auth/constants";
import { ViewProfile } from "@/interfaces/shared/view_profile";
import router from "@/router";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { generate_random_key, get_name_initials } from "@/utils/global";
import { get_color_by_name, get_random_color_hash } from "@/utils/colors";
import { Candidates } from "@/interfaces/data_objects/candidates";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import HighlightsAwards from "@/components/shared/view_profile/HighlightsAwards.vue";
import EducationDetails from "@/components/shared/view_profile/EducationDetails.vue";
import CertificationDetails from "@/components/shared/view_profile/CertificationDetails.vue";
import WorkExperience from "@/components/shared/view_profile/WorkExperience.vue";
import Personality from "@/components/shared/view_profile/Personality.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import ProfilePicture from "@/components/shared/view_profile/ProfilePicture.vue";
import UserDesignation from "@/components/shared/view_profile/UserDesignation.vue";
import UserName from "@/components/shared/view_profile/UserName.vue";
import UserRating from "@/components/shared/view_profile/UserRating.vue";
import { GET_TOP_MATCHING_JOB } from "@/store/modules/candidates/constants";
import EditProfile from "@/components/shared/view_profile/EditProfile.vue";
import UserProfileLoader from "@/components/shared/UserProfileLoader.vue";
export default Vue.extend({
  name: "ViewProfile",
  components: {
    EditProfile,
    UserRating,
    UserName,
    UserDesignation,
    ProfilePicture,
    SkillsWithGroups,
    Personality,
    WorkExperience,
    CertificationDetails,
    EducationDetails,
    HighlightsAwards,
    ExtraDetails,
    GoBackHeader,
    UserProfileLoader
  },
  data(): ViewProfile {
    return {
      loading: true,
      active_skill_group: "",
      profile_header_bg: require("@/assets/images/profile-bg.png"),
      designation: null,
      data: null,
      edit_profile_key: generate_random_key(),
      edit_profile: false,
      resume_btn_icon: require("@/assets/icons/bold/document-text.svg"),
      edit_btn_icon: require("@/assets/icons/linear/edit.svg"),
      industry_icon: require("@/assets/icons/bold/devices.svg"),
      track_icon: require("@/assets/icons/bold/mobile.svg"),
      experience_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      social_icons: {
        linkedin: require("@/assets/logos/linkedin-3.svg"),
        twitter: require("@/assets/logos/twitter.svg"),
        instagram: require("@/assets/logos/instagram.svg"),
        facebook: require("@/assets/logos/facebook.svg")
      }
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      user_profile: USER_PROFILE
    }),
    ...mapGetters("candidate", {
      get_top_matching_job: GET_TOP_MATCHING_JOB
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    }),
    get_title(): string {
      return this.$t("shared.applicant-profile").toString();
    }
  },
  async created() {
    this.data = {
      user: this.user_details,
      profile: this.user_profile
    };
    this.loading = false;
  },
  watch: {
    user_profile(n) {
      if (this.data) this.data.profile = n;
    }
  },
  methods: {
    generate_random_key,
    get_random_color_hash,
    get_name_initials,
    ...mapActions("auth", {
      fetch_profile_by_id: USER_PROFILE_BY_ID,
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    disable_edit_profile() {
      this.edit_profile = false;
      this.edit_profile_key = generate_random_key();
    },
    async init_data(params_id: number) {
      // If logged-in user => visiting own profile
      if (params_id && params_id === this.user_details.id) {
        this.data = {
          user: this.user_details,
          profile: this.user_profile
        };
        if (
          this.user_profile &&
          this.user_profile.profile &&
          Object.values(this.user_profile.profile.work_history_detailed)
            .length > 0
        ) {
          const work_details: Candidates.WorkHistoryDetails[] = Object.values(
            this.user_profile.profile.work_history_detailed
          );
          this.designation = `${work_details[0].job_title} @ ${work_details[0].organization}`;
        } else this.designation = null;
      }
      // If logged-in user => visiting other profiles
      else if (
        params_id &&
        params_id !== this.user_details.id &&
        this.user_details.role_id === this.get_role_by_name("recruiter").id
      ) {
        const user_profile: Candidates.CandidateDetails =
          await this.fetch_profile_by_id(params_id);
        const user_details = await this.fetch_user_by_id(params_id);
        if (user_details) {
          this.data = {
            user: user_details,
            profile: user_profile
          };
          if (
            user_profile &&
            user_profile.profile &&
            user_profile.profile.work_history_detailed
          ) {
            const work_details = Object.values(
              user_profile.profile.work_history_detailed
            );
            if (work_details.length > 0)
              this.designation = `${work_details[0].job_title} @ ${work_details[0].organization}`;
            else this.designation = null;
          } else this.designation = null;
        } else {
          if (
            this.get_role_by_name("recruiter").id === this.user_details.role_id
          )
            await router.push("/recruiter");
          else if (
            this.get_role_by_name("individual").id === this.user_details.role_id
          )
            await router.push("/candidate");
        }
      } else {
        if (this.get_role_by_name("recruiter").id === this.user_details.role_id)
          await this.$router.push("/recruiter");
        else await this.$router.push("/candidate");
      }
    },
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    transform_edit_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("stroke", get_color_by_name("white"));
      return icon;
    },
    transform_resume_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) {
        node.setAttribute("fill", get_color_by_name("white"));
        node.setAttribute("stroke", get_color_by_name("grey"));
      }
      return icon;
    }
  }
});
